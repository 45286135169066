import "./Game.css";
import "../../Base/Button/Button.css"
import { useDispatch, useSelector } from 'react-redux';
import FContainer from "../../Base/FContainer/FContainer";

import { motion } from "framer-motion";
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import GradientButtonMini from "../../Base/GradientButton/GradientButtonMini";

import { profile, gameEnd, setWSAction, setPMData } from '../../Gdata';

function Game({ refx, mode }) {

    console.log("РЕЖИМ ИГРЫ", mode);

    console.log("Старт Игры!!!");

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.isMobile);
    const game = useSelector(state => state.game);
    const mClass = isMobile ? "mobile" : "pc";

    const url = (isMobile) ? "./game2m/index.html" : "./game2/index.html";

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseOk = () => {
        //1 - предупреждаем 2 игрока
        dispatch(setWSAction({ com: "otherPlayerExit", payload: "" }));
        //2 end ws
        //3 end api
        // dispatch(setPMData(""));
        setOpen(false);

    }

    const goMain0 = () => {
        //если не сботом       
        if (mode !== "pc") {
            setOpen(true);
        }
        else {

            const jsn = {				
				is_computer: 0, // 1 - ходы компьютера, 0 - ходы пользователя
				hash: game.hash, // хэш игры
				result: {					
					point: 0,					
					result_type_id: 3,// результат, варианты описаны ниже 					
					branch_lose: 0, // кол-во веток, которое потеряно деревом игрока
				},
				// массив ходов
				steps: []
			};
            dispatch(gameEnd(jsn));

            const jsn2 = {				
				is_computer: 1, // 1 - ходы компьютера, 0 - ходы пользователя
				hash: game.hash, // хэш игры
				result: {					
					point: 0,					
					result_type_id: 3,// результат, варианты описаны ниже 					
					branch_lose: 0, // кол-во веток, которое потеряно деревом игрока
				},
				// массив ходов
				steps: []
			};
            dispatch(gameEnd(jsn2));

            dispatch(setPMData(""));
            dispatch(profile());
        }
        //если с ботом игнор
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }}>

            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <div className='dialog-cont'>
                        <div className='dialog-cont-pic'></div>
                        <div className='dialog-cont-name'>Внимание!</div>
                        <div className='dialog-cont-dis'>Выход из игры сейчас повлечёт поражение</div>
                        <div className='dialog-cont-btn'>
                            <GradientButtonMini text="OK" padding="60" fontSize="16" onClick={handleCloseOk} />
                            <div style={{ width: "20px" }}></div>
                            <GradientButtonMini text="Отмена" padding="60" fontSize="16" onClick={handleClose} />
                        </div>
                    </div>
                </Dialog>
            </div>

            <FContainer>
                <div className="Game">
                    <div className="game_container" >
                        <iframe title="game" className="iframe" src={url} ref={refx} />
                    </div>
                    <div className="back_btn btn" onClick={goMain0}></div>

                </div>
            </FContainer>
        </motion.div>
    )
}

export default Game;