class Api {

    static getUrl = (par) => {

        let prod = true;
        let url;
        if (window.location.href.indexOf("obrdev") !== -1 || window.location.href.indexOf("localhost") !== -1) {
                        //prod = false;
        }

        switch (par) {
            case "ws":
                url = (prod)
                    ? 'wss://sockets.gardenbattle.ltc-english.ru/?ws_token='
                    : 'wss://sockets.gardenbattle.ltc-english.obrdev.ru/?ws_token=';
                break;
            case "r":
                url = (prod)
                    ? "https://api-ltc.obr.nd.ru/api/"
                    : "https://api-ltc.obrdev.ru/api/";
                break;           

            default:
                url = (prod)
                    ? "https://api.gardenbattle.ltc-english.ru/api/"
                    : "https://api.gardenbattle.ltc-english.obrdev.ru/api/";
                break;
        }

        return url;
    }

    static isProd = () => {
        let prod = true;        
        if (window.location.href.indexOf("obrdev") !== -1 || window.location.href.indexOf("localhost") !== -1) {
                        prod = false;
        }
        return prod;
    }
}

export default Api;